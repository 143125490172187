//
//
//
//
//
//

export default {
  props: ['value', 'message']
}
